function addToStorage(key, value) {
    return localStorage.setItem(key, JSON.stringify(value))
}

function getFromStorage(key) {
    return JSON.parse(localStorage.getItem(key));
}

function isEmpty(str) {
    return (str.length === 0 || !str.trim());
}

export {addToStorage, getFromStorage, isEmpty}