import React from 'react'
import { withGlobalState } from '../../services/state';
import './toggler.css'

class Toggler extends React.Component {

    changeLanguage = (language) => {
        let { globalState, history } = this.props;
        globalState.setLanguage(language);
        language === 'en' ? history.push('/') : history.push('/ru')
    }

    render() {
        let { globalState, url } = this.props;
        return (
            <div className="dropdown">
                <div className="dropbtn">
                    <div className="lang-block">
                        <img src={url} alt="Language" />
                        <span>{globalState.language}</span>
                    </div>
                </div>
                <div className="dropdown-content">
                    <div className="lang-block" onClick={() => this.changeLanguage('ru')}>
                        <img src="http://icons.iconarchive.com/icons/custom-icon-design/flag-3/16/Russia-Flag-icon.png" alt="Russian Language" />
                        <span>RU</span>
                    </div>
                    <div className="lang-block" onClick={() => this.changeLanguage('en')}>
                        <img src="https://image.flaticon.com/icons/svg/555/555417.svg" alt="English Language" />
                        <span>EN</span>
                    </div>
                </div>
            </div>
        )
    }


}

export default withGlobalState(Toggler);