import axios from 'axios';

const ROOT_URL = 'https://www.googleapis.com/youtube/v3/search';
const API_KEY = 'AIzaSyCNf4jQqCa2cwgGr4nDdh9m5ioystOIgKo'

function YTSearch(options, callback) {
  const params = {
    part: 'snippet',
    key: API_KEY,
    q: options.term,
    maxResults: options.max,
    type: 'video'
  };
  axios.get(ROOT_URL, {
    params: params
  }).then(function (response) {
    if (callback) {
      callback(response.data.items);
    }
  }).catch(function (error) {
    console.error(error);
  });
};

export default YTSearch;