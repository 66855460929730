import React from 'react';
import Search from '../../components/search/';
import Sidebar from '../../components/sidebar/';
import YTSearch from '../../services/api';
import VideoPlayer from '../../components/videoplayer/';
import Toggler from '../../components/toggler/';
import * as Utils from '../../services/utils';
import './home.css';

const dictionary = {
    en: {
      placeholder: 'Search on YouTube...',
      sidebar: 'Watch History',
      sidebarEmpty: 'Empty History',
      url: 'https://image.flaticon.com/icons/svg/555/555417.svg',
      emptyPlaylist: 'There is no video in the playlist. Please, choose it'
    },
    ru: {
      placeholder: 'Поиск...',
      sidebar: 'История Просмотров',
      sidebarEmpty: 'Нет элементов',
      url: 'http://icons.iconarchive.com/icons/custom-icon-design/flag-3/16/Russia-Flag-icon.png',
      emptyPlaylist: 'В плейлисте отсутствует видео, пожалуйста, выберите его'
    }
}

class Home extends React.Component {
    state = {
        videos: [],
        resultStatus: false,
        selectedVideo: Utils.getFromStorage('selectedVideo') || null,
        historyList: Utils.getFromStorage('historyList') || []
    }

    search = term => {
        this.searchYT(term);
        this.showResult();
    }

    searchYT = term => {
        YTSearch({term, max: 10}, videos => {
            this.setState({ videos })
        })
    }

    addToHistory = (historyList, video) => {
        let idx = historyList.findIndex(el => {
            return el.id.videoId === video.id.videoId
        })
        
        if (idx !== -1) {
            historyList.splice(idx, 1);
        }

        if (historyList.length === 5) {
            historyList.pop();
        }
        
        let newHistoryList = [video, ...historyList];
        Utils.addToStorage('historyList', newHistoryList);

        return newHistoryList;
    }

    deleteFromHistory = (videoId) => {
        const newHistoryList = this.state.historyList.filter(video => {
            return video.id.videoId !== videoId
        });
        Utils.addToStorage('historyList', newHistoryList);
        this.setState({
            historyList: newHistoryList
        })
    }

    selectVideo = (id, video) => {
        Utils.addToStorage('selectedVideo', id);
        this.setState(state => {
            return {
                selectedVideo: id,
                historyList: this.addToHistory(state.historyList, video)
            }
        })
    }

    showResult() {
        this.setState({
            resultStatus: true
        })
    }

    hideResult = () => {
        this.setState({
            videos: [],
            resultStatus: false
        })
    }

    render() {
        let {globalState, history} = this.props;
        let {selectedVideo, videos, historyList, resultStatus} = this.state;
        let inputText = dictionary[globalState.language].placeholder;
        let sidebarText = dictionary[globalState.language].sidebar;
        let url = dictionary[globalState.language].url;
        let sidebarEmptyText = dictionary[globalState.language].sidebarEmpty;
        let emptyPlaylist = dictionary[globalState.language].emptyPlaylist;

        return (
            <div className="home">
                <div className="container">
                    <div className="row">
                        <div className="offset-md-3 col-md-6 col-10">
                            <Search
                                hideResult={this.hideResult}
                                resultStatus={resultStatus}
                                videosList={videos}
                                inputText={inputText} 
                                onSearchChange={this.onSearchChange}
                                onSelectHandler={this.selectVideo} 
                                onPress={this.search}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Sidebar 
                                onSelectHandler={this.selectVideo}
                                historyList={historyList}
                                deleteFromHistory={this.deleteFromHistory}
                                sidebarText={sidebarText}
                                sidebarEmptyText={sidebarEmptyText}/>
                        </div>
                        <div className="col-md-8">
                            <VideoPlayer emptyPlaylist={emptyPlaylist} videoId={selectedVideo}/>
                        </div>
                        <Toggler url={url} history={history}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;