import React from 'react';
import {StateProvider, withGlobalState, Consumer} from '../../services/state';
import Home from '../../pages/home/';
import Langs from '../../pages/langs/';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

function ProtectedRoute(props) {
  return <Consumer>
    {globalState => {
      return globalState.language ? <Route {...props} />
                                  : <Redirect to="/langs" />
    }}
  </Consumer>
}

class App extends React.Component {
  render() {
    return(
      <Router>
        <StateProvider>
          <Switch>
            <Route path="/langs" component={withGlobalState(Langs)}/>
            <ProtectedRoute exact path="/" component={withGlobalState(Home)}/>
            <ProtectedRoute path="/ru" component={withGlobalState(Home)} />
          </Switch>
        </StateProvider>
      </Router>
    )
  }
}

export default App;