import React from 'react';
import './videoplayer.css';

const VideoPlayer = ({videoId, emptyPlaylist}) => {

    const videoSrc= `https://www.youtube.com/embed/${videoId}`

    return(
        <div className="videoplayer">
            {
                videoId ? <iframe width="100%" height="100%" 
                            src={videoSrc} title="Video" frameBorder="0" allowFullScreen></iframe> : <span>{emptyPlaylist}</span>
            }
            
        </div>
    )
}

export default VideoPlayer;