import React from 'react';
import {isEmpty} from '../../services/utils';

import './search.css';

class Search extends React.Component {
    state = {
        term: ''
    }

    onSearchChange = (e) => {
        this.props.hideResult();
        this.setState({
            term: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onPress(this.state.term)
    }

    render() {
        let {inputText, videosList, onSelectHandler, resultStatus} = this.props;
        let {term} = this.state;
        let elements = videosList.map(video => {
            const {id, snippet: {thumbnails, title}} = video;
            return (
                <ResultItem 
                    key={id.videoId}
                    src={thumbnails.default.url} 
                    title={title}
                    onSelectHandler={() => onSelectHandler(id.videoId, video)}/>
            )
        });
        return (
            <div className="search">
                <form className="search-field" onSubmit={this.onSubmit}>
                    <input placeholder={inputText} className="search-input form-control" type="text" onChange={(e) => this.onSearchChange(e)}/>
                    <button type="submit" className="search-btn" disabled={isEmpty(term)}>
                        <i className="fa fa-search"></i>
                    </button>
                </form>
                {
                    (resultStatus && videosList.length) ? <ResultView hideResult={this.props.hideResult}>{elements}</ResultView> : null
                }
            </div>
        )
    }
}

const ResultView = (props) => {
    return (
        <>
            <ul className="search-result">
                {props.children}
            </ul>
            <div className="close-btn">
                <i className="fa fa-times" onClick={props.hideResult}></i>
            </div>
        </>
    )
}

const ResultItem = ({src, title, onSelectHandler}) => {
    return(
        <li className="list-item">
            <div className="left-side">
                <img src={src} alt="title"/>
                <div className="description">
                    <p className="video-title">{title}</p>
                </div>
            </div>
            <button className="btn-play" onClick={onSelectHandler}>
                <i className="fa fa-play-circle-o" aria-hidden="true"></i>
            </button>
        </li>
    )   
}

export default Search;