import React from 'react';
import './sidebar.css';

const Sidebar = props => {
    const {sidebarText, historyList, deleteFromHistory, onSelectHandler, sidebarEmptyText} = props;

    const elements = historyList.map(video => {
        const {id: {videoId}, snippet: {thumbnails, title}} = video;
        return (
            <SidebarElements 
                key={videoId}
                selectVideo={() => onSelectHandler(videoId, video)}
                onDelete={() => deleteFromHistory(videoId)}
                src={thumbnails.default.src}
                title={title}/>
        )
    })

    return (
        <>
            <h3>{sidebarText}</h3>
            <ul className="item-list list-group">
                {
                    (historyList.length > 0) ? elements : <li className="list-group-item">{sidebarEmptyText}</li>
                }
            </ul>
        </>
    )
}

const SidebarElements = props => {
    const {title, onDelete, selectVideo} = props

    return(
        <li className="list-group-item">
            <p className="video-name" onClick={selectVideo}>{title}</p>
            <button className="delete-btn" onClick={onDelete}>
                <i className="fa fa-times" aria-hidden="true"></i>
            </button>
        </li>
    )
}

export default Sidebar;