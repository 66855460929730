import React from 'react';
import ruIcon from '../../images/ru.png';
import enIcon from '../../images/gb.png';
import './langs.css';

export default class Langs extends React.Component {

  changeLanguage = (language) => {
    let {globalState, history} = this.props;
    globalState.setLanguage(language);
    language === 'en' ?  history.push('/') : history.push('/ru')
  }

    render() {
      return(
        <div className="languages">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <div className="en langs-block" onClick={(e) => this.changeLanguage('en')}>
                  <img src={enIcon} alt="English"/>
                </div>
              </div>
              <div className="col-6">
                <div className="ru langs-block" onClick={(e) => this.changeLanguage('ru')}>
                  <img src={ruIcon} alt="Russian"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      )
    }
}