import React from 'react';
import * as Utils from '../services/utils';

let LanguageContext = React.createContext();
let {Provider, Consumer} = LanguageContext; 

class StateProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: Utils.getFromStorage('language') || null,
      setLanguage: this.setLanguage
    };
  }

  setLanguage = (language) => {
    Utils.addToStorage('language', language);
    this.setState({ language })
  }

  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
      </Provider>
    )
  }
}

function withGlobalState(Component) {
  return function(props) {
    return <Consumer>
      {globalState => <Component {...props} globalState={globalState}/>}
    </Consumer>
  }
}

export {StateProvider, withGlobalState, Consumer}